import React from "react";
import axios from  "axios";
import * as config from "../config";
import Loader from "./Loader";

export default function AudienceList(props){

    const [data,setData] = React.useState([]);
    const [filter,setFilter] = React.useState({count:1});
    const [segments,setSegments] = React.useState([]);
    const [order,setOrder] = React.useState("count");
    const [loading,setLoading] = React.useState(false);
    const headerKeys = [
      "userid","fingerprint","count","value"
    ];

    React.useEffect(()=>{

      axios({
        url:config.API_URL+"segments",
        method:"GET"
      })
      .then(res=>{
        setSegments(res.data);
      });
    },[]);

    React.useEffect(()=>{
      setLoading(true);
      var formData = new FormData();
      Object.keys(filter).forEach(k=>{
        formData.append(k,filter[k]);
      });

      formData.append("order",order);
      axios({
        url:config.API_URL+"data",
        method:"POST",
        data:formData

      })
      .then(res=>{
        setData(res.data);
        setLoading(false);
      });
    },[filter]);

    const segmentOptions = segments.map(s=>{
      return (<option value={s}  key={`option${s}`}>{s}</option>)
    });

    const tableData = data.map(d=>{

      const values = headerKeys.map(k=>{
        return (<td>{d[k]}</td>);
      });

      return (<tr>
        {values}
      </tr>)
    });

    const tableHeader = headerKeys.map(k=>{
      return (<th>{k}</th>);
    });

    const updateValue = (e) => {
      setFilter({
        ...filter,
        value:e.target.value
      });
    }

    const updateThreshold = (e) => {
      setFilter({
        ...filter,
        count:e.target.value
      });
    };

    const updateUserFilter = (e) => {
      setFilter({
        ...filter,
        hhUser:e.target.checked ? "yes" : "no"
      });


  };

    return (<>
      <div className="tableFilters">
        <div>
          <select onChange={updateValue}>
            <option value="all">All Segments</option>
            {segmentOptions}
          </select>
        </div>

        <div>
          <input type="range" min={0} step={1} max={50} value={filter.count} onChange={updateThreshold}/>
          Threshold: <b>{filter.count}</b>
        </div>
        <div>
          <label>
            <input type="checkbox" onChange={updateUserFilter}/> Only Show Hub Hawaii Users
          </label>
        </div>
      </div>
      {loading ? <Loader inline={true}/> : (<>
          <p>Segment Count: {data.length}</p>
          <table>
            <tr>
              {tableHeader}
            </tr>
            {tableData}
          </table>
      </>)}
    </>);

}
