import React from "react";
import AdminBase from "./AdminBase.js";
import AudienceList from "./AudienceList";

export default function AdminHome(props){

  return (<AdminBase>
      <AudienceList/>
    </AdminBase>)

}
