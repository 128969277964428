import React from "react"
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import AdminHome from "./admin/AdminHome";
import {UserContextProvider} from "./context/userContext.js";
import AuthRoute from "./admin/AuthRoute";
import "./scss/style.scss";
import "./scss/forms.scss";

function App() {

  return (
    <UserContextProvider>
        <Router>
    			<Switch>

            <AuthRoute path="/" exact>
              <AdminHome/>
            </AuthRoute>
    			</Switch>
    		</Router>
    </UserContextProvider>
  );
}

export default App;
