import React from 'react';
import HubHawaiiLogin from "./HubHawaiiLogin";
import {Route} from "react-router-dom";
import {userContext} from "../context/userContext";
import AdminBase from "./AdminBase";


export default function AuthRoute({component,children,...rest}) {

	const [user,setUser] = React.useContext(userContext);


  return (<>
		{(user && user.admin) ? (
				<Route {...rest}>
					{children}
				</Route>
			) : (<AdminBase><div className="login"><HubHawaiiLogin/></div></AdminBase>)}

  </>);
}
