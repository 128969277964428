import React from "react";
import {Link} from "react-router-dom";
import UserIcon from "./UserIcon";
import "../scss/admin.scss";

export default function AdminBase({title,children}){
  React.useEffect(()=>{
    document.title = "HubHawaii Server Admin";
  },[]);
  return (
    <div id="holder" className="admin">
      <div id="header">
        <div className="container">
          <h1>Audience Segment Explorer</h1>
          <UserIcon/>
        </div>
      </div>
      <div id="content">
        <div className="container">
          {children}
        </div>
      </div>

    </div>
  );
}
