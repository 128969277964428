import React from "react";
export const userContext = React.createContext();

export function UserContextProvider({children}){
	const [user,setUserState] = React.useState(false);

  const setUser = (user,remember=false) => {
    if(remember){
      localStorage.setItem('user',JSON.stringify(user));
    }
    setUserState(user);
  }

  const logout = () => {
    localStorage.setItem('user',false);
    setUserState(false);
  }

  React.useEffect(()=>{
    var savedUser = localStorage.getItem('user');
    if(savedUser){
      savedUser = JSON.parse(savedUser);
      setUserState(savedUser);
    }


  },[]);


	return (
		<userContext.Provider value={[user,setUser,logout]}>
			{children}
		</userContext.Provider>

	)
}
